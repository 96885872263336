.accountContainer {
  height: 100%;
  min-height: fit-content;
  width: 100%;
  max-width: 100%;
}

.label {
  font-size: 16px;
  font-weight: 400;
  color: var(--charcoalLight);
  width: 310px;
  position: relative;

  .note {
    position: absolute;
    z-index: -1;
    top: 35px;
    left: 0;
  }

}

label>div {
  margin-right: 12px !important; // increasing the default margin-right of the grommet checkbox SVG
}

.inputContainer {
  width: calc(100% - 250px);

  .inputWrapper {
    position: relative;

    button {
      position: absolute;
      right: 0;
      bottom: 5px;
      background: transparent;
      border: 0;
      color: var(--grey);
      font-size: 22px;
      width: 40px;
      padding: 0;
    }
  }

  input {
    width: 100%;
    border-width: 0 0 2px 0;
    border-color: #c3d9da;
    color: var(--grey);
    font-size: 20px;
    line-height: 28px;
    font-weight: 900;

    &:not(.errorBorder):focus {
      border-color: #368185;
      color: var(--charcoal);
    }

    &.errorBorder {
      border-color: #FFCCD2FF;

      &:focus {
        border-color: var(--pink);
        color: var(--charcoal);
      }
    }
  }

  .errorSection {
    font-size: 12px;
    min-height: 24px;
    width: 100%;
    text-align: left;

    &.active {
      color: var(--red);
    }
  }
}

.tabs {
  background-color: white;

  &.mobile {

    >div:first-child {
      padding: 10px 0 10px;

      button span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  >div:first-child {
    padding: 20px;
    justify-content: left;

    button {
      padding-top: 5px;

      span {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.passwordResetButton {
  background-color: transparent;
  border: 0;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.mobileAccordion {

  &.passowrd {

    .inputContainer {
      width: calc(100% - 90px);
      padding-top: 10px;
    }

    .label {
      width: 90px;
    }

    >div>div>div {
      padding: 0 22px;

      &:first-child {
        padding: 22px;
      }
    }

    .updateButton {
      margin-bottom: 34px;
    }

    .errorSection {
      font-size: 12px;
      line-height: 18px;
      padding-top: 6px;

      &:not(.active) {
        color: var(--grey);
      }
    }
  }




  >div>div>div {
    padding: 22px 22px 34px;
  }

  .note {
    font-size: 12px;
    line-height: 18px;
    color: var(--grey);
    margin-bottom: 20px;
  }

  .label {
    padding-top: 6px;
    width: 58px;

    .note {
      font-size: 16px;
      font-weight: 400;
      color: var(--charcoalLight);
      width: 250px;
      position: absolute;
      z-index: -1;
      top: 35px;
      left: 0;
    }
  }

  .inputContainer {
    width: calc(100% - 50px);
  }

}

.updateButton {
  float: right;
}

button[class^="header"] {
  padding: 0 22px;
  border-bottom: 0;

  svg {
    right: 22px;
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 15px;
  line-height: 15px;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  color: var(--charcoal);

  &:hover {
    opacity: .5;
  }
}


.line {
  animation: growLine 2s ease-in-out forwards;
}

@keyframes growLine {

  0% {
    width: 0%;
    margin-left: 0%;
  }

  100% {
    width: 100%;
    margin-left: 0%;
  }
}
