$animationTime: .3s;

.accordionContainer {
  box-shadow: 0 4px 10px rgba(0, 0, 0, .15);
  border-radius: 10px;

  &.expand {

    .body {
      transform: scaleY(1);
      border-color: #DFDFDF;
      max-height: 1000px;
    }

    .icon {
      transform: translateY(-50%) rotateZ(180deg);
    }

    .noAbsoluteIcon {
      transform: rotateZ(180deg);
    }
  }

  &:last-child {

    .header {
      border-bottom: 0;
    }
  }
}

.header {
  position: relative;
  width: 100%;
  min-height: 70px;
  background: transparent;
  border: 0;
  padding: 0 50px;
  margin: 0;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #DFDFDF;
  font-weight: 500;
}

.icon {
  position: absolute;
  right: 54px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: var(--charcoalLight);
  transition: $animationTime ease-in-out transform;
}

.noAbsoluteIcon {
  font-size: 20px;
  color: var(--charcoalLight);
  transition: $animationTime ease-in-out transform;
}

.body {
  border-top: 1px solid transparent;
  max-height: 0;
  transition: $animationTime ease-in-out all;
  overflow: hidden;
}
